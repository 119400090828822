<div class="panel-body ">
    <img class="slika" src="assets/logoIF.png" />

    <div *ngIf="!resetiraj">
    <h3>FORTIS PRIJAVA</h3>
    <form role="form" novalidate #myForm="ngForm">    
        <div class="grupa">               
            <label class="col-4">Email:</label>  
                <input type="email" [email]="true" id="username" name="username" class="form-control" required [(ngModel)]="korisnik.username" 
                #username="ngModel" (keyup.enter)="onEnterKey($event)" #user>
              
            </div>    
            <div *ngIf="korisnik.username.length > 0 && username.invalid && (username.dirty && username.touched)" class="alert alert-danger">Unos mora biti email adresa!</div>

        <div class="grupa">   
            <label class="col-4">Lozinka:</label>
                <input [type]="show ? 'text' : 'password'" id="password" name="password" class="form-control" required [(ngModel)]="korisnik.password" 
                        #password="ngModel" (keyup.enter)="onEnterKeyInput(korisnik.username, korisnik.password)" #pass>

             <button class="passHide" (click)="passwordClick()">
                 <i *ngIf="show" class="fa fa-eye" aria-hidden="true"></i>
                 <i *ngIf="!show" class="fa fa-eye-slash" aria-hidden="true"></i>
             </button>
        </div>
          
        <button type="submit" class="btn" (click)="prijava(korisnik)" >Prijava</button>
        <div *ngIf="username.invalid && (username.dirty && username.touched) && password.invalid && (password.dirty && password.touched) || poruka" class="alert alert-danger">{{ poruka }}</div>
        <!-- <div *ngIf="poruka" class="alert alert-danger">{{ poruka }}</div> -->
    </form>     
    <a class="reset" (click)="zaboravljenaLoz()">Zaboravljena lozinka?</a>  
    </div> 

    <div *ngIf="resetiraj">
        <h3>NOVA LOZINKA</h3>
        <p>Unesite email adresu Vašeg korisničkog računa na koju će stići link za izmjenu lozinke</p>
        <form role="formReset" novalidate >    
            <div class="grupa">               
                <label class="col-4">Email:</label>  
                    <input type="email" [email]="true" id="reset" name="reset" class="form-control" required [(ngModel)]="emailReset"
                           (keyup.enter)="onEnterKeyReset($event)" #reset="ngModel" >                  
            </div>  
            <div *ngIf="reset.invalid && (reset.dirty && reset.touched)" class="alert alert-danger">Unos mora biti email adresa!</div>  
            <button type="submit" class="btn btnReset" (click)="resetPassword(emailReset)">Resetiraj lozinku</button>
            <a class="reset" (click)="zaboravljenaLoz()">Natrag na prijavu</a>  
        </form>
    </div>  
</div>

<div *ngIf="loader" class="circles">
    <div class="loader"></div>
  </div>