import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../services/api.service';
import { AppConfig } from '../config';

@Component({
  selector: 'app-moduli',
  templateUrl: './moduli.component.html',
  styleUrls: ['./moduli.component.css']
})
export class ModuliComponent implements OnInit {

  brojevi: Number[] =[];
  closeModal: any;
  idkorisnik: any;
  korisnik: string;
  public loader: boolean = false;
  mod: any = [];
  moduli: any;
  moduliPopis: any;
  moduliZaPrikaz = JSON.parse(localStorage.getItem("moduli") ||'');
  nazivFirme: any;
  nazivModula!: string;
  podaci: any;
  show = false;
  token: any;
  url: any;
  nefiskaliziraniRacuni: any;
  e_racuni:any;
  ugovoriPredIstek:any;
  titleNefisk: any;
  idfirma: any;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
      this.podaci = JSON.parse(localStorage.getItem('user') || '{}');
      this.token = localStorage.getItem('token'); 
      this.idkorisnik = +this._route.snapshot.params['id'];
      this.korisnik = this.podaci['name'];
      this.nazivFirme = this.podaci['imefirme'];
      this.idfirma = this.podaci['firma_idfirma'];
      this.loader = false;
   }

  ngOnInit(): void {
    this.popisModula();
  }

  odiNaModul(data: any){
    this.loader = true;
    // console.log(data);
    if(data.id === 1){
      //---- PONUDE I RAČUNI --------//
      this.nazivModula = 'ponude_i_racuni';
      this.url = AppConfig.ipAddressPonude + '#/webapp/';
    } else if(data.id === 2){
      //--------UGOVORI --------------//
      this.nazivModula = 'ugovori';
      this.url = AppConfig.ipAddressUgovori + '#/webapp/';
    } else if(data.id === 7){
       //--- SKLADIŠNO POSLOVANJE -----//
      this.nazivModula = 'skladisno_poslovanje';
      this.url = AppConfig.ipAddressSkladisno + '#/webapp/';
    } else if(data.id === 8){
       //--------- KNJIŽENJE ----------//
      this.nazivModula = 'knjizenje';
      this.url = AppConfig.ipAddressKnjizenje + '#/webapp/';
    } else if(data.id === 9){
       //----------- E-RAČUNI ---------//
      this.nazivModula = 'e-racun';
      this.url = AppConfig.ipAddressERacuni + '#/webapp/';
    } else if(data.id === 10){
      //----------- IZVJEŠTAJI ---------//
      this.nazivModula = 'izvjestaji';
      this.url = AppConfig.ipAddressIzvjestaji + '#/webapp/';
    } else if(data.id === 11){
      //----------- LICENCE - ADMINISTRACIJA KORISNIKA ---------//
      this.nazivModula = 'administracija_korisnika';
      this.url = AppConfig.ipAddressLicence + '#/webapp/';
    } else if(data.id === 12){
      //----------- KADROVI ---------//
      this.nazivModula = 'kadrovi';
      this.url = AppConfig.ipAddressKadrovi + '#/webapp/';
    } else if(data.id === 13){
      //----------- CRM ---------//
      this.nazivModula = 'crm';
      this.url = AppConfig.ipAddressCrm + '#/webapp/';
    } else if(data.id === 14){
      //----------- CRM ---------//
      this.nazivModula = 'evidencija_godisnjih';
      this.url = AppConfig.ipAddressGodisnji + '#/webapp/';
    }
    else if(data.id === 15){
      //----------- CRM ---------//
      this.nazivModula = 'radni_nalozi';
      this.url = AppConfig.ipAddressNalozi + '#/webapp/';
    }
    else if(data.id === 3){
      //----------- CRM ---------//
      this.nazivModula = 'maloprodaja';
      this.url = AppConfig.ipAddressMaloprodaja + '#/webapp/';
    }
    if(data.id === 16){
      //----------- CRM ---------//
      this.nazivModula = 'natjecaji_za_posao';
      this.url = AppConfig.ipAdresaNatjecajiZaPosao + '#/webapp/';
    }
    
  var putanja = this.url + this.idkorisnik + '/' + this.podaci.firma_idfirma + '/' + this.token + '/' + this.nazivModula;
      try {
          window.open(putanja, '_blank');
          this.loader = false;
      }
      catch (err){
          this.loader = false;
      }
  }

  odjava(){
    this.apiService.odjava({username : localStorage.getItem('email')}).subscribe(res => {
      // console.log(res);
      // if(res.success){
        localStorage.clear();
        this._router.navigate(['/login']);
      // }
    })
  }

  toggle(){
    this.show = !this.show;
  }

  triggerModal(content: any) {    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
      // console.log(res);
      this.odjava();
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  triggerEuro(content: any) {    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-euro'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  popisModula(){
    this.moduli = [];
    this.apiService.modulPopis({access_token: this.token,idfirma:this.idfirma}).subscribe(res => {
      // console.log(res.data);
      if(res.success){
        res.data.forEach((elem1) => {elem1;
          this.moduliZaPrikaz.forEach((elem2) => {elem2;
            if(elem1.idmodul=== elem2){
              this.moduli.push({id: elem1.idmodul, naziv: elem1.mod_naziv, icon: 'fa fa-file' ,nefisk:elem1.broj_nefiskal_racuna,e_racun:elem1.broj_neposlanih_eracuna,predistek:elem1.broj_isteklih_ugovora});       
            } 
          });
          this.moduli.sort((a, b) => a.id - b.id);
        for(let i=0;i<this.moduli.length;i++){
          if(this.moduli[i].naziv == "Ponude i računi"){
            this.moduli[i].icon = 'fa fa-copy';
          }
          if(this.moduli[i].naziv == "Ugovori"){
            this.moduli[i].icon = 'fa fa-file';
          }
          if(this.moduli[i].naziv == "Skladišno poslovanje"){
            this.moduli[i].icon = 'fa fa-truck';
          }
          if(this.moduli[i].naziv == "Knjiženje"){
            this.moduli[i].icon = 'fa fa-book';
          }
          if(this.moduli[i].naziv == "E-račun"){
            this.moduli[i].icon = 'fa fa-edge';
          }
          if(this.moduli[i].naziv == "Izvještaji"){
            this.moduli[i].icon = 'fa fa-edit';
          }
          if(this.moduli[i].naziv == "Administracija korisnika"){
            this.moduli[i].icon = 'fa fa-key';
          }
          if(this.moduli[i].naziv == "Kadrovi"){
            this.moduli[i].icon = 'fa fa-group';
          }
          if(this.moduli[i].naziv == "CRM"){
            this.moduli[i].icon = 'fa fa-user';
          }
          if(this.moduli[i].naziv == "Evidencija godišnjih"){
            this.moduli[i].icon = 'fa fa-calendar';
          }
          if(this.moduli[i].naziv == "Radni nalozi"){
            this.moduli[i].icon = 'fa fa-desktop';
          }
          if(this.moduli[i].naziv == "Maloprodaja"){
            this.moduli[i].icon = 'fa fa-money';
          }
        }
      });
      } else {
        localStorage.clear();
        this._router.navigate(['/login']);
      }
    })
  }
}