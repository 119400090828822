import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  ipadresa = AppConfig.ipAddressPrijava

  apiUrl = this.ipadresa + 'api/'; 

  constructor(private _http: HttpClient) {    }

  prijava(data:any){
    return this._http.post(this.apiUrl + 'authenticateweb', data)    
    .pipe(map((res: any) => res ));
  }

  odjava(data:any){
    return this._http.post(this.apiUrl + 'odjavaweb', data)    
    .pipe(map((res: any) => res ));
  }

  modulPopis(data:any){
    return this._http.post(this.apiUrl + 'modulPopis', data)    
    .pipe(map((res: any) => res ));
  }

  ZaboravljenaLozinka(data:any){
    return this._http.post(this.apiUrl + 'reset_ZaboravljenaLozinka', data)    
    .pipe(map((res: any) => res ));
  }

  resetLozinke_Fortis(data:any){
    return this._http.post(this.apiUrl + 'resetLozinke_Fortis', data)    
    .pipe(map((res: any) => res ));
  }

}