import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild("user", { static: false }) public user!: ElementRef;
  @ViewChild("pass", { static: false }) public pass!: ElementRef;
  @ViewChild("emailR", { static: false }) public emailR!: ElementRef;

  emailReset: any;
  korisnik = { username: '', password: ''};
  loader: boolean = false;
  poruka: any;
  provjera:any;
  resetiraj: boolean = false;
  submitted = false;
  show: boolean = false;

  constructor(
    private apiService: ApiService,
    private _router: Router
  ) {
    this.show = false;
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.user.nativeElement.focus();
    }, 0);
  }
  
  onEnterKey(e: any){
    this.pass.nativeElement.focus();
    this.show = false;
  }
  
  onEnterKeyInput(username: any, password: any){
    let podaci = { username: username, password: password};
    this.prijava(podaci);
  }

  prijava(podaci: any){
    // console.log(podaci); 
    this.submitted = true;
    if(podaci.username && podaci.password != ''){
      this.loader = true;
      this.apiService.prijava(podaci).subscribe(res => {
        // console.log(res);
        this.loader = false;
        if(res.success === true){
          var prikazModula = Array.from(res.data[0].moduli.split(','),Number);
          localStorage.setItem("email", podaci.username);
          localStorage.setItem("moduli", JSON.stringify(prikazModula));
          localStorage.setItem('user', JSON.stringify(res.data[0]));
          localStorage.setItem('token', (res.data[0].token));
            this._router.navigate(['moduli/' + res.data[0].id]);
        } else {
          this.poruka = res.message;
          // console.log(this.poruka);
        }
      })
    } else {
      this.poruka = 'Popunite polja ispravno';
    }
  }

  passwordClick(){
    // console.log('pass');
    this.show = !this.show;
  }

  zaboravljenaLoz(){
    this.resetiraj = !this.resetiraj;
    this.korisnik = { username: '', password: '' };
  }

  resetPassword(data){
    // console.log(data);
    this.loader = true;
    this.apiService.ZaboravljenaLozinka({username: data}).subscribe(res => {
      // console.log(res);
      this.loader = false;
      this.poruka = res.message;
      if(res.success === true){
        this.resetiraj = !this.resetiraj;
      }
    })
  }

  onEnterKeyReset(e){  }

}