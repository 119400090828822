<div class="panel-body ">
    <img class="slika" src="assets/logoIF.png" />

    <h3>PROMJENA LOZINKE</h3>
    <form role="form" novalidate #myForm="ngForm">      
        <div class="grupa">   
            <label class="col-4">Nova lozinka:</label>
                <input [type]="show ? 'text' : 'password'" id="password" name="password" class="form-control" required [(ngModel)]="korisnik.lozinka" 
                        #password="ngModel" (keyup.enter)="onEnterKeyInput(korisnik.lozinka)" #pass>
             <button class="passHide" (click)="passwordClick()">
                 <i *ngIf="show" class="fa fa-eye" aria-hidden="true"></i>
                 <i *ngIf="!show" class="fa fa-eye-slash" aria-hidden="true"></i>
             </button>
        </div>

        <div class="grupa">   
            <label class="col-4">Ponovi lozinku:</label>
                <input [type]="show2 ? 'text' : 'password'" id="password" name="password" class="form-control" required [(ngModel)]="korisnik.novalozinka" 
                        #password2="ngModel" (keyup.enter)="onEnterKeyInput2(korisnik)"  #pass2>
             <button class="passHide" (click)="passwordClick2()">
                 <i *ngIf="show2" class="fa fa-eye" aria-hidden="true"></i>
                 <i *ngIf="!show2" class="fa fa-eye-slash" aria-hidden="true"></i>
             </button>
        </div>

        <button type="submit" class="btn" (click)="novaLozinka(korisnik)">Spremi lozinku</button> 
        <div *ngIf="password.invalid && (password.dirty && password.touched) && password2.invalid && (password2.dirty && password2.touched)" 
                class="alert alert-danger">Popunite polja ispravno</div>
        <div *ngIf="poruka" class="alert alert-danger">{{ poruka }}</div>
        <div *ngIf="porukaInfo" class="alert alert-info">{{ porukaInfo }}</div>
    </form>

</div>


<div *ngIf="loader" class="circles">
    <div class="loader"></div>
  </div>