import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../config';

@Injectable()
export class AuthService {
  loggedIn = false;
  token: any;  
  korisnik: any;
  baseUrl115: string = AppConfig.ipAddressPrijava + 'api/';                   
  headers = new HttpHeaders();

  constructor(private router: Router,private _http: HttpClient) {
      this.imaToken();
    }

  login(a: any){
    return this._http.post(this.baseUrl115 + 'prijava', a, { headers: this.headers })
    .pipe(map((res: any) => res));
  }

  logIn(){
    this.korisnik = localStorage.getItem('operater')    
    // this.login({sifra: login, lozinka: passord, prava: 1});
    this.loggedIn = true;
    this.router.navigate(['/moduli/' + this.korisnik]);
    // this.token = localStorage.getItem('token') 
  }

  get isLoggedIn() {
    return this.loggedIn;
  }
   
  imaToken(){
    this.token = localStorage.getItem('token') 
      if(this.token != null || this.token != undefined){
          this.loggedIn = true;
      } else {
          this.loggedIn = false;
      }  
    } 
}

@Injectable()
export class AuthGuardService implements CanActivate {
    korisnik: any;
    token: any;
    constructor(private router: Router, private authService: AuthService) {
        this.token = localStorage.getItem('token')
    }

     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authService.isLoggedIn;
        const token = this.token;
        if (user && token) {            
            return true;
        } else {
            this.router.navigate(['/login']);
            localStorage.clear();
            localStorage.clear();
            return false;
        }
    }
}
