import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ModuliComponent } from './moduli/moduli.component';
import { NovaLozinkaComponent } from './nova-lozinka/nova-lozinka.component';
import { AuthGuardService } from './services/auth.service';

const routes: Routes = [ 
  { path: 'moduli/:id', component: ModuliComponent, canActivate: [ AuthGuardService ] },
  { path: 'nova-lozinka/:token', component: NovaLozinkaComponent }, 
  { path: 'login', component: LoginComponent },
  { path: '**',   redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  providers: [ AuthGuardService ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

