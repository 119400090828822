import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-nova-lozinka',
  templateUrl: './nova-lozinka.component.html',
  styleUrls: ['./nova-lozinka.component.css']
})
export class NovaLozinkaComponent implements OnInit {

  @ViewChild("pass", { static: false }) public pass!: ElementRef;
  @ViewChild("pass2", { static: false }) public pass2!: ElementRef;

  korisnik = { lozinka: '', novalozinka: ''};
  show: boolean = false;
  show2: boolean = false;
  poruka: any;
  token: any;
  porukaInfo: any;
  loader = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.show = false;
    this.show2 = false;
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token') || '';
    });
    setTimeout(()=>{
      this.pass.nativeElement.focus();
    }, 0);
  }

  onEnterKeyInput(e: any){
    this.show = false;
    this.pass2.nativeElement.focus();
  }

  onEnterKeyInput2(pass){
    this.show = false;
    this.novaLozinka(pass);
  }

  novaLozinka(e){
    this.loader = true;
    if(e.lozinka != e.novalozinka){
      this.poruka = 'Lozinke se ne podudaraju!';
    } else if (e.lozinka.length < 1 || e.novalozinka.length < 1) {
      this.poruka = 'Popunite polja ispravno';
    } else {
      this.poruka = '';
      this.api.resetLozinke_Fortis({token: this.token, novaLozinka: this.korisnik.novalozinka}).subscribe(res => {
        // console.log(res);
        this.loader = false;
        if(res.success == true){
          this.router.navigate(['/login']);
          this.porukaInfo = res.message;
          this.poruka = '';
        } else {
          this.poruka = res.message;
          this.porukaInfo = '';
        }
      })
    }
  }

  passwordClick(){
    this.show = !this.show;
  }

  passwordClick2(){
    this.show2 = !this.show2;
  }

}